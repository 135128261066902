import React from "react";
import Header from "../components/Header";
import UserSurvey from "../components/UserSurvey.js";
import Sidebar from "../components/Sidebar.js";

export default function QualtricsSurvey() {
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Header></Header>
      <div style={{ display: "flex", height: "calc(100% - 64px)" }}>
        <Sidebar />
        <div
          style={{
            display: "grid",
            placeItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <UserSurvey />
        </div>
      </div>
    </div>
  );
}
