import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import StepIcon from "@mui/material/StepIcon";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { DEMOGRAPHICS_SURVEY_URL } from "../constants";

const eft_cues_num = parseInt(process.env.REACT_APP_EFT_CUES_NUM, 10);

const steps = [
  {
    label: "Login",
    content: "Check your personal email for your username and password.",
  },
  {
    label: "Generate EFTs",
    content: `Interact with the chatbot to generate your EFT Cues. Create ${eft_cues_num} timeframes to move on.`,
  },
  {
    label: "Usability Survey",
    content: "Complete the survey",
  },
  {
    label: "Return to Qualtrics",
    content: "",
  },
];

// Custom icon component
function CustomStepIcon(props) {
  const { active, completed } = props;
  if (completed) {
    return (
      <CheckIcon
        style={{
          backgroundColor: "#861F41",
          borderRadius: "50%",
          padding: 3,
        }}
      />
    );
  } else {
    return <StepIcon {...props} />;
  }
}

export default function VerticalLinearStepper(props) {
  const development = process.env.REACT_APP_DEVELOPMENT === "true";

  const [activeStep, setActiveStep] = React.useState(0);
  const [continueButton, setContinueButton] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const logout = async () => {
    try {
      await fetch("/auth/logout");
    } catch (error) {
      console.error("Logout failed", error);
    }
    localStorage.clear();
  };

  React.useEffect(() => {
    async function fetchMyAPI() {
      try {
        const response = await fetch("/api/user_checkpoints");
        const { user_id, values, checkpoints } = await response.json();

        localStorage.setItem("user_id", user_id);

        if (
          props.cues.length === eft_cues_num &&
          checkpoints.needs_usability_assessment
        ) {
          setContinueButton(true);
          setActiveStep(2);
        }
      } catch (error) {
        setActiveStep(0); // Reset to initial step on error
      }
    }

    fetchMyAPI();
  }, [props.cues.length]);

  React.useEffect(() => {
    // Function to fetch checkpoint data
    const fetchCheckpoints = async () => {
      try {
        const response = await fetch("/api/user_checkpoints");
        if (!response.ok) {
          if (response.status === 401 && location.pathname !== "/signup" && location.pathname !== "/about") {
            // Handle 401 Unauthorized
            setActiveStep(0);
            localStorage.clear();
            navigate("/login");
            return;
          }
        }

        const { user_id, values, checkpoints } = await response.json();

        localStorage.setItem("user_id", user_id);

        if (checkpoints.needs_usability_assessment) {
          setContinueButton(true);
        }
        if (checkpoints.needs_generated_eft_cues) {
          development ? console.log(development) : navigate("/home");
          setActiveStep(1);
        } else if (checkpoints.needs_usability_assessment) {
          development ? console.log(development) : navigate("/usability");
          setActiveStep(2);
        } else {
          development ? console.log(development) : navigate("/home");
          setActiveStep(3);
        }
      } catch (error) {
        setActiveStep(0); // Reset to initial step on error
      }
    };

    fetchCheckpoints();
  }, [location.pathname]);

  return (
    <Box sx={{ maxWidth: 400, overflowY: "auto" }}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{
          "& .MuiStepConnector-line": {
            height: "75px",
          },
        }}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              sx={{
                ".MuiStepLabel-label": {
                  color: "white !important",
                },
              }}
              StepIconComponent={(props) => <CustomStepIcon {...props} />} // Pass props to the custom component
              optional={
                index === 3 ? (
                  <Typography variant="caption" sx={{ color: "white" }}>
                    Last step
                  </Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {/* <Box sx={{ mb: 15 }}></Box> */}
              {step.content}

              {step.label === "Usability Survey" ? (
                <>
                  <Divider
                    style={{ marginTop: "1.2rem", marginBottom: "1.2rem" }}
                  />
                  <button
                    disabled={!continueButton}
                    style={{ backgroundColor: "#861F41" }}
                    onClick={() => navigate("/usability") & setActiveStep(2)}
                  >
                    Continue
                  </button>
                </>
              ) : (
                ""
              )}
              {step.label === "Return to Qualtrics" ? (
                <>
                  <Divider
                    style={{ marginTop: "1.2rem", marginBottom: "1.2rem" }}
                  />
                  {/* change this below to qualtrics link */}
                  <button
                    style={{ backgroundColor: "#861F41" }}
                    onClick={
                      async () => {
                        await logout();
                        window.location.href = (DEMOGRAPHICS_SURVEY_URL + "?id=" + localStorage.getItem("user_id"));
                      }
                    }
                  >
                    Done
                  </button>
                </>
              ) : (
                ""
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Typography sx={{ pt: 2 }}>
          All steps completed - you&apos;re finished
        </Typography>
      )}
    </Box>
  );
}
