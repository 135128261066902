import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Snackbar } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Alert from "@mui/material/Alert";
import Sidebar from "../components/Sidebar";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Collapse } from "@mui/material";
import "../components/HomePage.css";

const defaultValues = {
  first_name: "",
  last_name: "",
  emailaddress: "",
  password: "",
  confirm: "",
  affiliation: "", // previously 'org'
  categories: [],
  topics: [],
};

var notificationMessage = "";
const defaultErrorValues = {
  hasError: false,
  first_name: {
    hasError: false,
    errorText: "",
  },
  last_name: {
    hasError: false,
    errorText: "",
  },
  emailaddress: {
    hasError: false,
    errorText: "",
  },
  password: {
    hasError: false,
    errorText: "",
  },
  confirm: {
    touched: false,
    hasError: false,
    errorText: "",
  },
};

const defaultPassword = {
  eight: {
    name: "At least 8 characters",
    achieved: false,
  },
  number: {
    name: "At least 1 number",
    achieved: false,
  },
  lower: {
    name: "At least 1 lowercase character",
    achieved: false,
  },
  upper: {
    name: "At least 1 uppercase character",
    achieved: false,
  },
  special: {
    name: "At least 1 special character",
    achieved: false,
  },
};

export default function SignUp() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [errorValues, setErrorValues] = React.useState(defaultErrorValues);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [passwordReq, setPasswordReq] = React.useState(defaultPassword);
  const [isFocused, setIsFocused] = React.useState(false);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    // console.log(formValues.password, errorValues)

    if (
      name === "first_name" ||
      name === "last_name"
      // || name === "area"
    ) {
      if (value.length > 20) {
        if (!errorValues[name].hasError)
          setErrorValues({
            ...errorValues,
            hasError: true,
            [name]: {
              hasError: true,
              errorText: "Must be less than 20 characters",
            },
          });
      } else {
        if (errorValues[name].hasError) {
          setErrorValues({
            ...errorValues,
            hasError:
              Object.values(errorValues).filter(
                (prop) =>
                  typeof prop === "object" &&
                  prop.hasOwnProperty("hasError") &&
                  prop.hasError === true
              ).length > 1,
            [name]: defaultErrorValues[name],
          });
        }
      }
    }

    if (name === "emailaddress") {
      if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)) {
        if (!errorValues[name].hasError)
          setErrorValues({
            ...errorValues,
            hasError: true,
            [name]: {
              hasError: true,
              errorText: "Invalid email",
            },
          });
      } else {
        if (errorValues[name].hasError) {
          setErrorValues({
            ...errorValues,
            hasError:
              Object.values(errorValues).filter(
                (prop) =>
                  typeof prop === "object" &&
                  prop.hasOwnProperty("hasError") &&
                  prop.hasError === true
              ).length > 1,
            [name]: defaultErrorValues[name],
          });
        }
      }
    }
    if (name === "confirm") {
      value = value.toString();
      if (value !== formValues.password) {
        setErrorValues({
          ...errorValues,
          hasError: true,
          [name]: {
            touched: true,
            hasError: true,
            errorText: "Passwords must match",
          },
        });
      } else {
        setErrorValues({
          ...errorValues,
          hasError:
            Object.values(errorValues).filter(
              (prop) =>
                typeof prop === "object" &&
                prop.hasOwnProperty("hasError") &&
                prop.hasError === true
            ).length > 1,
          [name]: {
            touched: true,
            hasError: false,
            errorText: "",
          },
        });
      }
    }

    if (name === "password") {
      value = value.toString();
      // console.log(value, formValues.confirm)

      // handle setting the requirements
      setPasswordReq({
        ...passwordReq,
        ["eight"]: {
          name: passwordReq.eight.name,
          achieved: value.length >= 8,
        },
        ["number"]: {
          name: passwordReq.number.name,
          achieved: value.search(/\d/) !== -1,
        },
        ["lower"]: {
          name: passwordReq.lower.name,
          achieved: value.search(/[a-z]/) !== -1,
        },
        ["upper"]: {
          name: passwordReq.upper.name,
          achieved: value.search(/[A-Z]/) !== -1,
        },
        ["special"]: {
          name: passwordReq.special.name,
          achieved: value.search(/[\!\@\#\$\%\^\&\*\(\)\_\+]/) !== -1,
        },
      });
      //end

      //checks the confirm password is equal
      let confirm_error = {
        touched: errorValues.confirm.touched,
        hasError: false,
        errorText: "",
      };
      if (errorValues.confirm.touched && formValues.confirm !== value) {
        confirm_error = {
          touched: true,
          hasError: true,
          errorText: "Passwords must match",
        };
      }

      if (value.length < 8) {
        setErrorValues({
          ...errorValues,
          hasError: true,
          [name]: {
            hasError: true,
            errorText: "Must be at least 8 characters",
          },
          ["confirm"]: confirm_error,
        });
      } else if (value.length > 50) {
        setErrorValues({
          ...errorValues,
          hasError: true,
          [name]: {
            hasError: true,
            errorText: "Must be less than 50 characters",
          },
          ["confirm"]: confirm_error,
        });
      } else if (value.search(/\d/) == -1) {
        setErrorValues({
          ...errorValues,
          hasError: true,
          [name]: {
            hasError: true,
            errorText: "Must contain at least 1 number",
          },
          ["confirm"]: confirm_error,
        });
      } else if (value.search(/[a-z]/) == -1) {
        setErrorValues({
          ...errorValues,
          hasError: true,
          [name]: {
            hasError: true,
            errorText: "Must contain at least 1 lower case letter",
          },
          ["confirm"]: confirm_error,
        });
      } else if (value.search(/[A-Z]/) == -1) {
        setErrorValues({
          ...errorValues,
          hasError: true,
          [name]: {
            hasError: true,
            errorText: "Must contain at least 1 upper case letter",
          },
          ["confirm"]: confirm_error,
        });
      } else if (value.search(/[\!\@\#\$\%\^\&\*\(\)\_\+]/) == -1) {
        setErrorValues({
          ...errorValues,
          hasError: true,
          [name]: {
            hasError: true,
            errorText:
              "Must contain at least 1 special character(!,@,#,$,%,^,&,*,(,),_,+)",
          },
          ["confirm"]: confirm_error,
        });
      } else {
        // console.log(Object.values(errorValues).filter(prop => typeof prop === 'object' && prop.hasOwnProperty('hasError') && prop.hasError === true).length)
        setErrorValues({
          ...errorValues,
          hasError:
            confirm_error.hasError ||
            Object.values(errorValues).filter(
              (prop) =>
                typeof prop === "object" &&
                prop.hasOwnProperty("hasError") &&
                prop.hasError === true
            ).length -
              (errorValues[name].hasError ? 1 : 0) >
              1,
          [name]: defaultErrorValues[name],
          ["confirm"]: confirm_error,
        });
      }
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
    // setTimeout(() => console.log(name + ": "+value+", ", value === formValues.confirm), 3);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      first_name,
      last_name,
      emailaddress,
      password,
      affiliation,
      categories,
      topics,
    } = formValues;

    try {
      const req = {
        username: emailaddress,
        password: password,
      };
      const response = await axios.post("/auth/signup", req);
      const userData = response.data;

      // Assuming the API returns the user details directly in the response
      localStorage.setItem("user", JSON.stringify(userData));
      notificationMessage = "Success, redirecting...";
      localStorage.setItem("temp-email", emailaddress);
      localStorage.setItem("temp-password", password);
      localStorage.setItem("temp-survey", false);
      setOpenSuccess(true);
      setTimeout(() => navigate("/home"), 3000); // Fixed the navigation issue

      // Check the role of the user and navigate accordingly
    } catch (err) {
      console.error("Error during signup:", err);
      const errorMessage =
        err.response && err.response.data && err.response.data.error
          ? err.response.data.error.details
          : "Failure...";
      notificationMessage = "Error: " + errorMessage;
      setOpenError(true);
    }
  };

  return (
    <Box style={{ height: "100vh", overflow: "hidden" }}>
      <div style={{ height: "100vh", overflow: "hidden" }}>
        <Header />
        <div style={{ display: "flex", height: "calc(100% - 64px)" }}>
          <Sidebar />
          <div
            style={{
              overflowY: "auto",
              height: "100%",
              display: "flex",
              flex: "4",
              justifyContent: "center",
            }}
          >
            <Box
              style={{
                overflowY: "auto",
                height: "100%",
                width: "100%",
              }}
            >
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleSuccessClose}
              >
                <Alert onClose={handleSuccessClose} severity="success">
                  {notificationMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={openError}
                autoHideDuration={6000}
                onClose={handleErrorClose}
              >
                <Alert onClose={handleErrorClose} severity="error">
                  {notificationMessage}
                </Alert>
              </Snackbar>

              <Container component="main" maxWidth="xs">
                <Box
                  sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Signup
                  </Typography>
                  <Box
                    component="form"
                    required
                    onSubmit={handleSubmit}
                    sx={{ mt: 3 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="first_name"
                          required
                          fullWidth
                          id="first_name"
                          label="First Name"
                          autoFocus
                          value={formValues.first_name}
                          onChange={handleInputChange}
                          error={errorValues.first_name.hasError}
                          helperText={errorValues.first_name.errorText}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="last_name"
                          label="Last Name"
                          name="last_name"
                          autoComplete="family-name"
                          value={formValues.last_name}
                          onChange={handleInputChange}
                          error={errorValues.last_name.hasError}
                          helperText={errorValues.last_name.errorText}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="emailaddress"
                          label="Email Address"
                          name="emailaddress"
                          autoComplete="email"
                          value={formValues.emailaddress}
                          onChange={handleInputChange}
                          error={errorValues.emailaddress.hasError}
                          helperText={errorValues.emailaddress.errorText}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="new-password"
                          value={formValues.password}
                          onChange={handleInputChange}
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                          error={errorValues.password.hasError && !isFocused}
                          helperText={
                            isFocused ? "" : errorValues.password.errorText
                          }
                        />
                      </Grid>
                      <Collapse in={isFocused}>
                        <Grid item xs={12}>
                          <Box sx={{ padding: "18px" }}>
                            {Object.values(passwordReq).map(
                              (requirement, index) => (
                                <Typography
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "8px",
                                  }}
                                >
                                  {requirement.achieved ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                  ) : (
                                    <CloseIcon sx={{ color: "#ff1744" }} />
                                  )}{" "}
                                  {requirement.name}
                                </Typography>
                              )
                            )}
                          </Box>
                        </Grid>
                      </Collapse>

                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="confirm"
                          label="Confirm Password"
                          type="password"
                          id="confirm"
                          autoComplete="new-password"
                          value={formValues.confirm}
                          onChange={handleInputChange}
                          error={errorValues.confirm.hasError}
                          helperText={errorValues.confirm.errorText}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={errorValues.hasError}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Link
                          href="#"
                          variant="body2"
                          component={RouterLink}
                          to="/login"
                        >
                          Already have an account? Sign in
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </Box>
          </div>
        </div>
      </div>
    </Box>
  );
}
