import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Person } from "@mui/icons-material";
import Sidebar from "../components/Sidebar";

const defaultValues = {
  affiliation: "",
  categories: [],
  topics: [],
};

export default function Profile() {
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();
  const [formValues, setFormValues] = React.useState({
    ...defaultValues,
  });

  const user = JSON.parse(localStorage.getItem("user")) || {};

  React.useEffect(() => {
    if (!Object.keys(user).length > 0) {
      navigate({ pathname: "/login" });
      return;
    }

    async function fetchProfile() {
      try {
        const userLocalStorage = JSON.parse(localStorage.getItem("user"));
        const access_token = userLocalStorage
          ? userLocalStorage.access_token
          : null;

        if (!access_token) {
          console.error("access_token not found in local storage");
          setError("Authorization access_token is missing.");
          return;
        }

        const { data } = await axios.get("/user/profile", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });

        setFormValues({ ...formValues, ...data });

        if (user.role) {
          // Additional code to handle roles and display buttons
          const roleButtons = {
            Experimenter: (
              <Button
                type="button"
                fullWidth
                variant="outlined"
                sx={{ mt: 1 }}
                onClick={() => navigate("/experiments")}
              >
                Go to Experiments
              </Button>
            ),
            Curator: (
              <Button
                type="button"
                fullWidth
                variant="outlined"
                sx={{ mt: 1 }}
                onClick={() => navigate("/curator")}
              >
                Go to Curates
              </Button>
            ),
          };

          return roleButtons[user.role];
        }
      } catch (err) {
        console.log("Error fetching profile:", err);
        setError("Failed to fetch profile.");
      }
    }

    fetchProfile();
  }, [user.id, user.googleId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(
        `/api/v1/users/${user.id || user.googleId}`,
        {
          ...formValues,
        }
      );
    } catch (err) {
      console.error("Error updating profile:", err);
    }
  };

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Header />
      <div
        style={{
          display: "flex",
          height: "calc(100% - 64px)",
        }}
      >
        <Sidebar />
        <div
          style={{
            overflowY: "auto",
            height: "100%",
            display: "flex",
            flex: "4",
          }}
        >
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <Person />
              </Avatar>
              <Typography component="h1" variant="h5">
                Profile
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      name="first_name"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      value={formValues.first_name || ""}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="last_name"
                      autoComplete="lname"
                      value={formValues.last_name || ""}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email_id"
                      autoComplete="email"
                      value={formValues.emailaddress || ""}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="affiliation"
                      label="University/Organization"
                      name="affiliation"
                      autoComplete="affiliation"
                      value={formValues.affiliation || ""}
                      onChange={handleInputChange}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="roles_list"
                      label="Role"
                      name="roles_list"
                      autoComplete="roles_list"
                      value={formValues.roles_list || ""}
                      onChange={handleInputChange}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Save
                </Button>
              </Box>
              {error && <Typography color="error">{error}</Typography>}
            </Box>
            <Footer sx={{ mt: 5 }} />
          </Container>
        </div>
      </div>
    </div>
  );
}
