import React from "react";
import Header from "../components/Header";
import UsabilityQuestion from "../components/UsabilityQuestion";
import "../components/UsabilityQuestion.css";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { DEMOGRAPHICS_SURVEY_URL } from "../constants";

export default function UsabilityAssessment() {
  const defaultValues = [
    {
      question: "I think that I would like to use this system frequently.",
      value: 0,
    },
    {
      question: "I found the system unnecessarily complex.",
      value: 0,
    },
    {
      question: "I thought the system was easy to use.",
      value: 0,
    },
    {
      question:
        "I think that I would need the support of a technical person to be able to use this system.",
      value: 0,
    },
    {
      question:
        "I found the various functions in this system were well integrated.",
      value: 0,
    },
    {
      question: "I thought there was too much inconsistency in this system.",
      value: 0,
    },
    {
      question:
        "I would imagine that most people would learn to use this system very quickly.",
      value: 0,
    },
    {
      question: "I found the system very cumbersome to use.",
      value: 0,
    },
    {
      question: "I felt very confident using the system.",
      value: 0,
    },
    {
      question:
        " I needed to learn a lot of things before I could get going with this system.",
      value: 0,
    },
  ];
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [errors, setErrors] = React.useState(true);
  const navigate = useNavigate();

  const updateFormValue = (index, newValue) => {
    setFormValues((prevFormValues) => {
      const updatedFormValues = [...prevFormValues];
      updatedFormValues[index].value = newValue;
      setErrors(
        updatedFormValues.filter((question) => question.value > 0).length !==
        updatedFormValues.length
      );
      return updatedFormValues;
    });
  };

  const logout = async () => {
    try {
      await fetch("/auth/logout");
    } catch (error) {
      console.error("Logout failed", error);
    }
    localStorage.clear();
  };

  const submit = async () => {
    console.log(formValues);
    setErrors(true);
    try {
      const response = await fetch('/api/submit_usability_assessment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: formValues
        })
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/login");
        }
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data); // Handle the response data here
      window.alert("Thank you for submitting the usability assessment! You will be redirected to Qualtrics to complete an exit survey.");
      (async () => {
        await logout();
        window.location.href = (DEMOGRAPHICS_SURVEY_URL + "?id=" + localStorage.getItem("user_id"));
      })();
    }
    catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Header></Header>
      <div style={{ display: "flex", height: "calc(100% - 64px)" }}>
        <Sidebar />
        <div
          style={{
            overflow: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            paddingTop: "1.2rem",
          }}
        >
          <div style={{ width: "80%" }}>
            <h1>Usability Assessment</h1>
            <h3>
              Please answer the following questions to the best of your ability
              using the Likert scale 1-5.
            </h3>
            <div className="flex-grid" style={{ padding: "1.2rem 0 1.2rem 0" }}>
              <div className="col"></div>
              <div className="col">1. Strongly Disagree</div>
              <div className="col">2. Disagree</div>
              <div className="col">3. Neutral</div>
              <div className="col">4. Agree</div>
              <div className="col">5. Strongly Agree</div>
            </div>
            <Divider />

            {formValues.map((question, key) => (
              <UsabilityQuestion
                key={key}
                question={question.question}
                index={key}
                updateFormValue={updateFormValue}
              />
            ))}
            <div
              style={{
                padding: "1.2rem",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <button onClick={submit} disabled={errors}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
