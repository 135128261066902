import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { DeveloperBoard } from "@mui/icons-material";

export default function SignIn() {
  const navigate = useNavigate();
  const [creds, setCreds] = React.useState({ username: "", password: "" });
  const [error, setError] = React.useState("");
  const development = process.env.REACT_APP_DEVELOPMENT === "true";

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setCreds({
      ...creds,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const req = {
        username: creds.username,
        password: creds.password,
      };
      const response = await axios.post("/auth/login", req);
      const userData = response.data;

      // Assuming the API returns the user details directly in the response
      localStorage.setItem("user", JSON.stringify(userData));

      // Check the role of the user and navigate accordingly
      navigate("/home");
    } catch (err) {
      console.error("Error during login:", err);
      const errorInfo = err.response.data.error;
      if (400 == errorInfo.code) {
        setError(`The server refused to process the login request. Please contact an administrator and provide these error details: ${errorInfo.message}: ${errorInfo.details}`);
      } else if (401 == errorInfo.code) {
        setError("Your login credentials are incorrect.");
      } else if (403 == errorInfo.code) {
        setError("You have already completed all necessary steps. Thank you for participating!");
      }
    }
  };

  return (
    <Box>
      <div style={{ height: "100vh", overflow: "hidden" }}>
        <Header />
        <div style={{ display: "flex", height: "calc(100% - 64px)" }}>
          <Sidebar />
          <div
            style={{
              overflowY: "auto",
              height: "100%",
              display: "flex",
              flex: "4",
            }}
          >
            <Container component="main" maxWidth="xs">
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="User ID"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={creds.username}
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={creds.password}
                    onChange={handleInputChange}
                  />
                  {error && (
                    <Typography color="error" variant="body2">
                      {error}
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    color="primary"
                  >
                    Sign In
                  </Button>

                  <Grid container>
                    <Grid item>

                      {development ?
                        <Link
                          href="#"
                          variant="body2"
                          component={RouterLink}
                          to="/signup"
                        >
                          {"Don't have an account? Sign Up"}
                        </Link> : null}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Footer sx={{ mt: 8, mb: 4 }} />
            </Container>
          </div>
        </div>
      </div>
    </Box>
  );
}
