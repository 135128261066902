import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Box from "@mui/material/Box";
import Profile from "./pages/Profile";
import About from "./pages/About";
import UsabilityAssesment from "./pages/UsabilityAssessment";
import Qualtrics from "./pages/QualtricsSurvey";

import axios from "axios";

export default function App() {
  async function login(username, password) {
    try {
      const response = await axios.post("/auth/login", {
        email_id: username,
        password: password,
      });

      if (response.data) {
        return { success: true, user: response.data };
      }
    } catch (error) {
      return { success: false, error: error.response.data };
    }

    return { success: false, error: "Authentication failed" };
  }

  return (
    <Box sx={{ overflowX: "hidden", margin: 0 }}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route
            path="/login"
            element={
              <Login
                onLogin={async (username, password) => {
                  try {
                    const response = await login(username, password);

                    if (response.success) {
                      console.log("Authentication successful!");
                    } else {
                      console.error(response.error);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
              />
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/about" element={<About />} />
          <Route path="/usability" element={<UsabilityAssesment />} />
          <Route path="/qualtrics" element={<Qualtrics />} />
        </Routes>
      </Router>
    </Box>
  );
}
