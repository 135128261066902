import * as React from "react";
import "./HomePage.css";
import Footer from "./Footer";
import Message from "./Message";
import Sidebar from "./Sidebar";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export default function Recommendations() {
  const navigate = useNavigate();

  const [input, setInput] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const [cues, setCues] = React.useState([]);

  const messagesEndRef = React.useRef(null);

  const [loading, setLoading] = React.useState(0);
  const [sendError, setSendError] = React.useState(false);
  const [showCues, setShowCues] = React.useState(true);
  const [fetching, setFetching] = React.useState(true);
  const eft_cues_num = parseInt(process.env.REACT_APP_EFT_CUES_NUM, 10);


  function scrollToBottom() {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const [selectedIndex, setSelectedIndex] = React.useState(messages.length);

  // Function to handle increasing index
  const increaseIndex = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex < cues.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  // Function to handle decreasing index
  const decreaseIndex = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  function handleSendMessage() {
    if (input !== "") {
      setMessages([...messages, { content: input, user: true, new: true }]);
      fetchData(input);
      setInput("");
    }
  }
  function startMessage() {
    setMessages([...messages, { content: " ", user: true, new: true }]);
    fetchData(" ");
    setInput("");
  }

  function handleInputChange(e) {
    setInput(e.target.value);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      return;
    } else if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const resendMessages = async (message) => {
    // await fetchMessages();
    // setMessages([...messages, { content: message, user: true, new: true }]);
    setSendError(false);
    try {
      await fetchMessages();
      setMessages([...messages]);
      await fetchData(message);
    } catch {
      setSendError(true);
    }

    // setMessages([...messages, { content: message, user: true, new: true }]);
  };
  const fetchMessages = async () => {
    try {
      const response = await fetch("/api/get_messages");
      if (!response.ok) {
        if (response.status === 401) {
          navigate("/login");
        }
        throw new Error("Failed to fetch messages");
      }
      const data = await response.json();
      // console.log(data);
      const messageArray = data.messages || [];
      const fetchedMessages = messageArray.map((message) => ({
        content: message.content,
        user: message.role === "user" ? true : false,
        new: false,
      }));
      setMessages(fetchedMessages);
      let newCues = data.eft_cues || [];
      setCues(newCues);
      setSelectedIndex(newCues.length - 1);
    } catch (error) {
      console.log(error);
      console.error("Error fetching messages:", error);
    }
    setFetching(false);
  };

  React.useEffect(() => {
    fetchMessages();
    focusInputBox();
  }, []);

  const focusInputBox = () => {
    // this should not require a delay, but alas
    setTimeout(() => {
      document.getElementById("outlined-multiline-static").focus();
    }, 0.1);
  }

  const fetchData = async (message) => {
    console.log("fetching...");
    setLoading(1);

    try {
      setLoading(1);
      const response = await fetch("/api/send_message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: message,
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/login");
        }
        setSendError(true);
        throw new Error("Network response was not ok");
      }

      const reader = response.body.getReader();
      let partialData = "";

      const decodeStream = async () => {
        // add blank message to start
        setLoading(2);
        console.log(loading);
        let newMessage = { content: "", user: false, new: true };
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        // process streamed JSON response objects
        while (true) {
          const { done, value } = await reader.read();
          setLoading(2);
          if (done) {
            setLoading(0);
            focusInputBox();
            break;
          }

          partialData += new TextDecoder().decode(value);
          const lines = partialData.split("\n");
          partialData = lines.pop(); // save incomplete data for next iteration

          lines.forEach((line) => {
            if (line.trim() !== "") {
              // concatenate token to new message object
              const jsonData = JSON.parse(line);

              newMessage.content += jsonData.content;
              // replace message list to force react re-render (react moment)
              setMessages((prevMessages) => [...prevMessages]);
              // console.log(jsonData);
              if (jsonData.eft_cues) {
                setCues(jsonData.eft_cues);
                setSelectedIndex(jsonData.eft_cues.length - 1);
              }
            }
          });
        }
      };
      // setLoading(0);
      setSendError(false);

      decodeStream().catch((error) => {
        console.error("Error decoding stream:", error);
      });
    } catch (error) {
      setSendError(true);
      console.error("There was a problem with your fetch operation:", error);
    }
    setLoading(0);
  };

  return (
    <div className="container">
      <Sidebar cues={cues} />
      <div className="main-page">
        {fetching ? (
          <div className="fetching-box">
            <CircularProgress />
          </div>
        ) : (
          <div className="text-area">
            <div className="response-area">
              {messages.map((message, index) => (
                <Message
                  key={index}
                  user={message.user}
                  message={message.content}
                  new={message.new}
                  scroll={scrollToBottom}
                  error={sendError}
                  resend={resendMessages}
                  length={messages.length}
                  index={index}
                />
              ))}
              {loading === 1  ? (
                <div className="loading-box">
                  <div className="loading">...</div>
                </div>
              ) : null}
              {messages.length === 0 && !fetching ? (
                <div className="center-start-message">
                  <div className="start-message">Press START to Begin</div>
                  <div>
                    <button className="start-about" onClick={startMessage}>
                      START
                    </button>
                  </div>
                </div>
              ) : (
                <div style={{ padding: "1rem" }}></div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>
        )}
        {!showCues ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "none",
              overflowX: "hidden",
            }}
          >
            <div
              className="cue-index"
              style={{
                position: "absolute",
                marginTop: "-2rem",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
              onClick={() => setShowCues(true)}
            >
              Show {cues.length} EFT Cues
            </div>
          </div>
        ) : null}
        <div className="input-box">
          {cues.length > 0 && showCues ? (
            <div className="eft-caption">
              <button className="next-arrow" onClick={decreaseIndex}>
                <ArrowBackIosNewIcon />
              </button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    display: "table-CellTower",
                    verticalAlign: "middle",
                    textAlign: "center",
                    overflowY: "auto",
                    maxHeight: "10em",
                    overflowWrap: "break-word",
                    hyphens: "auto",
                    wordBreak: "break-word",
                  }}
                >
                  "{cues[selectedIndex]}"
                </p>
                <div className="cue-index" onClick={() => setShowCues(false)}>
                  Hide {selectedIndex + 1}/{cues.length}
                </div>
              </div>
              <button className="next-arrow" onClick={increaseIndex}>
                <ArrowForwardIosIcon />
              </button>
            </div>
          ) : null}
          <FormControl
            className="form-control"
            sx={{ m: ".1em", width: "100%" }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
            <OutlinedInput
              id="outlined-multiline-static"
              label=""
              multiline
              maxRows={6}
              type="text"
              onChange={handleInputChange}
              value={input}
              disabled={
                loading !== 0
                || sendError
                || messages.length === 0
                || (!messages[messages.length - 1].user && messages[messages.length - 1].content.trim().endsWith("DONE"))
              }
              onKeyDown={handleKeyPress}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle send"
                    onClick={handleSendMessage}
                    edge="end"
                    disabled={loading !== 0 ? true : false}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
}
