import React from "react";
import Box from "@mui/material/Box";
import "./Sidebar.css";
import VerticalLinearStepper from "./VerticalLinearStepper";
import Footer from "./Footer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export default function Sidebar(props) {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const [showSidebarTab, setShowSidebarTab] = React.useState(false);

  const toggleSidebar = () => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
      setTimeout(() => setShowSidebarTab(true), 500);
    } else {
      setShowSidebarTab(false);
      setIsSidebarOpen(true);
    }
  };

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 720) {
        toggleSidebar();
      } else if (!isSidebarOpen) {
        toggleSidebar();
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isSidebarOpen ? null : (
        <div
          className={`sidebar-tab ${showSidebarTab ? "visible" : ""}`}
          onClick={toggleSidebar}
          style={{ width: showSidebarTab ? "auto" : "0" }}
        >
          <KeyboardArrowRightIcon />
        </div>
      )}

      <Box
        sx={{
          position: "relative",
          left: 0,
          display: "flex",
          width: isSidebarOpen ? "18em" : "0",
          height: "100%",
          overflow: "hidden",
          textWrap: "wrap",
          transition: "width 0.5s ease-in-out",
          justifyContent: "space-between",
        }}
      >
        <div className={`side-bar ${isSidebarOpen ? "open" : ""}`}>
          <div className="left-arrow">
            {" "}
            Complete the steps:
            <div className="left-arrow-container">
              <KeyboardArrowLeftIcon
                sx={{ color: "white", cursor: "pointer" }}
                onClick={toggleSidebar}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <VerticalLinearStepper style={{ overflowY: "auto" }} cues={props.cues || []} />
            <Footer style={{ padding: ".5rem" }} />
          </div>
        </div>
      </Box>
    </>
  );
}
