import * as React from "react";
import "./UserSurvey.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { DEMOGRAPHICS_SURVEY_URL } from "../constants";

export default function UserSurvey() {
  const navigate = useNavigate();

  const [consent, setConsent] = React.useState(false);

  const handleConsentChange = (event) => {
    setConsent(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    localStorage.setItem("temp-survey", true);
    window.location.href = DEMOGRAPHICS_SURVEY_URL;
    // setTimeout(() => navigate("/home"), 3000); // Fixed the navigation issue
  };

  return (
    <div
      style={{
        height: "100vh",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <div
        style={{
          overflowY: "auto",
          height: "calc(100% - 64px)",
        }}
      >
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Consent to Survey
            </Typography>

            <div style={{ paddingTop: "1rem" }}>
              Before you proceed, we ask for your consent to voluntarily take
              part in this study. Your participation is entirely voluntary, and
              you have the right to withdraw at any time without penalty.
            </div>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={consent}
                    onChange={handleConsentChange}
                    color="primary"
                  />
                }
                label=" I consent to participate in the survey."
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="primary"
                disabled={!consent}
              >
                Continue to Survey
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
    </div>
  );
}
