import * as React from "react";
import Header from "../components/Header";
import Home from "../components/Home";

export default function Search() {
  return (
    <>
      <div style={{ height: '100vh', overflow:'hidden' }}>
        <Header />
        <Home />
      </div>
    </>
  );
}