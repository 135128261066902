import * as React from "react";
import { Container, Typography, Box } from "@mui/material";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

export default function About() {
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Header />
      <div style={{ display: "flex", height: "calc(100% - 64px)" }}>
        <Sidebar />
        <div
          style={{
            overflowY: "auto",
            height: "100%",
            display: "flex",
            flex: "4",
          }}
        >
          <Container component="main" sx={{ my: 1 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ textAlign: "center", margin: 3 }}
            >
              <strong>Thank you for participating in this session!</strong>
              <Typography variant="subtitle1" gutterBottom>Please hit <u>Done</u> to finish your session.</Typography>
            </Typography>
            <hr></hr>
            <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
              <strong>Privacy Policy </strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              This website was built as a course project for CS 4624 at Virginia
              Tech. This website is not intended for commercial use. This page
              is used to inform visitors regarding our policies with the
              collection, use, and disclosure of Personal Information if anyone
              decided to use our Service. If you choose to use our Service, then
              you agree to the collection and use of information in relation to
              this policy. The Personal Information that we collect is used for
              providing and improving the Service. We will not use or share your
              information with anyone except as described in this Privacy
              Policy. The terms used in this Privacy Policy have the same
              meanings as in our Terms and Conditions.
            </Typography>
            <Typography variant="h6" gutterBottom>
              <strong>Information Collection and Use</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information, including but not limited to email, first name, last
              name and profile image URL. The information that we request will
              be retained by us and used as described in this privacy policy.
              The app does not use or share your data with any third party
              services.
            </Typography>
            <Typography variant="h6" gutterBottom>
              <strong>Log Data</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              We want to inform you that whenever you use our Service, we will
              log data such as, but not limited to, user queries, click info and
              dwell time. This data helps improve the service for the user.
            </Typography>
            <Typography variant="h6" gutterBottom>
              <strong>Security</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              We value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </Typography>
            <Typography variant="h6" gutterBottom>
              <strong>External Links</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. We have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </Typography>
            <Typography variant="h6" gutterBottom>
              <strong>Changes to This Privacy Policy</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              We may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page. This policy is effective as of 2024-5-14
            </Typography>
            <Typography variant="h6" gutterBottom>
              <strong>Contact Us</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us at saraahmadi@vt.edu
            </Typography>
          </Container>
        </div>
      </div>
    </div>
  );
}
