import React from "react";
import "./UsabilityQuestion.css"
import RadioGroup from '@mui/material/RadioGroup';
import MyFormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';


export default function UsabilityQuestion({ question, index, updateFormValue }) {
  const handleValueChange = (newValue) => {
    updateFormValue(index, newValue); // Call the updateFormValue function with the new value
  };
  return (
    <RadioGroup name="use-radio-group" defaultValue="first">
      <div className="flex-grid">
        <div className="col" style={{ display: 'flex', alignSelf: 'center', width: '100%', padding: '1.2rem 1.2rem 1.2rem 0' }}>{index + 1}. {question}</div>

        <MyFormControlLabel className="col" value="1" label="1" onChange={() => handleValueChange(1)} control={<Radio />} />
        <MyFormControlLabel className="col" value="2" label="2" onChange={() => handleValueChange(2)} control={<Radio />} />
        <MyFormControlLabel className="col" value="3" label="3" onChange={() => handleValueChange(3)} control={<Radio />} />
        <MyFormControlLabel className="col" value="4" label="4" onChange={() => handleValueChange(4)} control={<Radio />} />
        <MyFormControlLabel className="col" value="5" label="5" onChange={() => handleValueChange(5)} control={<Radio />} />


      </div>
      <Divider />

    </RadioGroup>

  );
}
