import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
  LibraryBooks,
  Science,
  Person,
  Settings,
  Info,
  Logout,
  House,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const development = process.env.REACT_APP_DEVELOPMENT === "true";
  const [showDrawer, setShowDrawer] = useState(false);

  const user = JSON.parse(localStorage.getItem("user")) || {};

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const isLoggedIn = async () => {
    try {
      const response = await fetch("/api/user_checkpoints");
      if (!response.ok) {
        if (response.status === 401) {
          return false;
        }
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const handleNavigation = async () => {
    const loggedIn = await isLoggedIn();
    if (loggedIn) {
      navigate("/home");
    }
  };

  const logout = async () => {
    try {
      await fetch("/auth/logout");
      localStorage.clear();
    } catch (error) {
      console.error("Logout failed", error);
    }
    localStorage.clear();
    navigate({ pathname: "/login", replace: true });
  };

  // Determine user role
  // console.log("User object:", user);
  const isCurator = user.roles_list === "curator";
  const isExperimenter = user.roles_list === "experimenter";

  return (
    <AppBar position="static" sx={{ boxShadow: 0 }}>
      <Toolbar>
        {!development ? null : (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
            <Drawer open={showDrawer} onClose={toggleDrawer}>
              <List sx={{ mx: 1 }}>
                {/* Common items for all users */}
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/home">
                    <ListItemIcon>
                      <House />
                    </ListItemIcon>
                    <ListItemText primary={"Home"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/profile">
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <ListItemText primary={"Profile"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/about">
                    <ListItemIcon>
                      <Info />
                    </ListItemIcon>
                    <ListItemText primary={"About"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/usability">
                    <ListItemIcon>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Usability Assessment"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/qualtrics">
                    <ListItemIcon>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={"User Survey"} />
                  </ListItemButton>
                </ListItem>

                {/* Items for Experimenter */}
                {(isExperimenter || isCurator) && (
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/experiments">
                      <ListItemIcon>
                        <Science />
                      </ListItemIcon>
                      <ListItemText primary={"Experimenter"} />
                    </ListItemButton>
                  </ListItem>
                )}

                {/* Items for Curator */}
                {isCurator && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton component={Link} to="/admin">
                        <ListItemIcon>
                          <Settings />
                        </ListItemIcon>
                        <ListItemText primary={"Settings"} />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton component={Link} to="/curator">
                        <ListItemIcon>
                          <LibraryBooks />
                        </ListItemIcon>
                        <ListItemText primary={"Curator"} />
                      </ListItemButton>
                    </ListItem>
                  </>
                )}

                <ListItem disablePadding>
                  <ListItemButton onClick={logout}>
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Drawer>
          </IconButton>
        )}
        <div style={{ flexGrow: 1, textDecoration: "none" }}>
          <Button onClick={handleNavigation} sx={{ textTransform: "none" }}>
            <Typography
              variant="h6"
              sx={{ textDecoration: "none" }}
              color="white"
            >
              EFT Chatbot
            </Typography>
          </Button>
        </div>

        {Object.keys(user).length <= 0 ? (
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
        ) : (
          <Avatar sx={{ bgcolor: "secondary.main" }}>
            {(user.firstName || "").charAt(0) + (user.lastName || "").charAt(0)}
          </Avatar>
        )}
        {Object.keys(user).length > 0 ? (
          user.imageUrl ? (
            <Avatar src={user.imageUrl} alt={`${user.givenName || ""}`} />
          ) : (
            <Button
              color="inherit"
              onClick={logout}
              component={Link}
              sx={{
                borderRadius: 0,
                "&:hover": {
                  //you want this to be the same as the backgroundColor above
                  backgroundColor: "#a52650",
                },
              }}
            >
              Logout
            </Button>
          )
        ) : (
          <Button
            color="inherit"
            component={Link}
            to="/login"
            sx={{
              borderRadius: 0,
              "&:hover": {
                //you want this to be the same as the backgroundColor above
                backgroundColor: "#a52650",
              },
            }}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}
